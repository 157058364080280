import React, { useState } from 'react';
import { SelectWithButton } from './respuestas/verRespuestas';
import { Button } from "@material-tailwind/react";
import { Filial } from './respuestas/filial';
import { Division } from './respuestas/division';

const App: React.FC = () => {
  // Estado inicial como null para no mostrar ningún componente al inicio
  const [activeComponent, setActiveComponent] = useState<string | null>(null);
  const [contraseña, setcontraseña] = useState<string | null>(null);
  const [pasePermitido, setpasePermitido] = useState<boolean | false>();

  const handleButtonClick = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if(contraseña === "Hakoe"){
      setpasePermitido(true);
    }
  }


  return (
    <div className=" mx-auto p-4">
      {!pasePermitido && (
          <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Ingresa para ver resultados del radar
            </h2>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleButtonClick}>
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Contraseña
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    onChange={ e => setcontraseña(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div className="mt-6">
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {pasePermitido && (
        <div className="container mx-auto p-4">
        <div className="flex justify-center space-x-4 mb-4">
          <button
            onClick={() => setActiveComponent('filial')}
            className={`px-4 py-2 rounded text-white ${activeComponent === 'filial' ? 'bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'}`}>
            Filial
          </button>
          <button
            onClick={() => setActiveComponent('division')}
            className={`px-4 py-2 rounded text-white ${activeComponent === 'division' ? 'bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'}`}>
            Division
          </button>
          <button
            onClick={() => setActiveComponent('equipo')}
            className={`px-4 py-2 rounded text-white ${activeComponent === 'equipo' ? 'bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'}`}>
            Equipo
          </button>
        </div>
   
        {activeComponent === 'filial' && (
          <div>
            <h1 className="text-3xl text-center text-[#042164] font-bold mb-4">Generar Informe por Filial</h1>
            <Filial />
          </div>
        )}
   
        {activeComponent === 'division' && (
          <div>
            <h1 className="text-3xl text-center text-[#042164] font-bold mb-4">Generar Informe por División</h1>
            <Division />
          </div>
        )}
   
        {activeComponent === 'equipo' && (
          <div>
            <h1 className="text-3xl text-center text-[#042164] font-bold mb-4">Generar Informe por Equipos</h1>
            <SelectWithButton />
          </div>
        )}
      </div>
      )}
    </div>
  );
};

export default App;