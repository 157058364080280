
import React, { useState} from 'react';
import axios from 'axios';

interface RespuestasAgrupadas {
    NPS: number;
    desviacionEstandar: number;
    cantidadGlobal: number;
    promedioGlobal: number;
    madurez: number;
}

export function Division(){
    const [division, setdivison] = useState<string>('');
    const [respuestas, setRespuestas] = useState<RespuestasAgrupadas | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [ImageURL, setImageURL] = useState<string>("");
    const [blob, setBlob] = useState<Blob | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fecha_i, setFecha_i] = useState<string | null>(null);
    const [fecha_t, setFecha_t] = useState<string | null>(null);


    const handleButtonClick = async (value: string | undefined) => {

        if(!fecha_i || !fecha_t){
            setError("Por favor, seleccione ambas fechas antes de continuar");
            return;
        }

        if(fecha_i>fecha_t ){
            setError("La fecha inicial no puede ser mayor que la fecha final. Por favor, selecciona un rango de fechas válido.");
            return;
        }

        setError('');

        try {
            //var blob = new Blob([], { type: 'image/jpeg' });
            var blob = null;
            setBlob(blob);
            setImageURL("");
            setLoading(true);
            var division = value;
            const response = await axios.get(`https://radar.hakoe.cl/Division/${division}`+'?fecha_in='+fecha_i+'&fecha_te='+fecha_t);
            
            const response_radar = await axios.post('https://radar.hakoe.cl/Respuestas/Radar',response.data);
            console.log("Respuesta recibida:", response.data);
            await recibir_imagen(response_radar.data);
            setRespuestas(response.data);
            return;
        } catch (error) {
            setBlob(null);
            setImageURL("");
            setError("No hay respuestas para el periodo de tiempo seleccionado")
            setLoading(false);
            return;
        }
    };



        const recibir_imagen = async (data: any) => {
            if (!data) return;
            try {
              // Hacemos una solicitud POST a la URL externa con el objeto JSON
                const response = await axios.post('https://us-central1-estrategia-384920.cloudfunctions.net/function-1', data);
                var decodedData = atob(response.data);
                let arrayBuffer = new ArrayBuffer(decodedData.length);
                let uint8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < decodedData.length; i++) {
                uint8Array[i] = decodedData.charCodeAt(i); }
                var blob = new Blob([uint8Array], { type: 'image/jpeg' });
                const blobUrl = URL.createObjectURL(blob);
                setBlob(blob);

                let n_preguntas = data.length;
                    if(n_preguntas===24){
                        setImageURL("../tabla producto.png");
                    }
                    else if (n_preguntas===25){
                        setImageURL("../tabla servicio.png");
                }
                console.log(n_preguntas);
            } catch (error) {
              // Manejamos errores en la solicitud
                console.error('Error al obtener los datos:', error);
            }
            finally{
                setLoading(false);
            }
    };

        function getMaturityLevel(madurez: any) {
            const level = parseFloat(madurez);
            if (level <= 1.7) return "Inicial";
            if (level <= 2.4) return "Desarrollando";
            if (level <= 3.2) return "Evolucionado";
            return "Performando";
        }

return (
        <div className="flex flex-col items-center gap-4">
            <div className="w-full md:w-1/2 flex flex-col items-start">
                <label  className="text-lg font-semibold text-gray-700 mb-2">Fecha inicial</label>
                <input type="date" id="fecha-inicial" onChange={ e => setFecha_i(e.target.value)} className="w-full rounded-md border border-gray-300 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"/>
            </div>

            <div className="w-full md:w-1/2 flex flex-col items-start">
                <label  className="text-lg font-semibold text-gray-700 mb-2">Fecha final</label>
                <input type="date" id="fecha-final" onChange={ e => setFecha_t(e.target.value)} className="w-full rounded-md border border-gray-300 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"/>
            </div>
            <div className="flex justify-center space-x-4">
                <button
                    onClick={() => handleButtonClick('IT')}
                    className={`bg-[#1976d2] text-white px-4 py-2 rounded hover:bg-[#073079] ${division === 'IT' ? 'bg-[#042164]' : ''}`}>
                    IT
                </button>
                <button
                    onClick={() => handleButtonClick('Innovación')}
                    className={`bg-[#1976d2] text-white px-4 py-2 rounded hover:bg-[#073079] ${division === 'Innovación' ? 'bg-[#042164]' : ''}`}>
                    Innovación
                </button>
                <button
                    onClick={() => handleButtonClick('Producto Individuales y Masivos')}
                    className={`bg-[#1976d2] text-white px-4 py-2 rounded hover:bg-[#073079] ${division === 'Producto Individuales y Masivos' ? 'bg-[#042164]' : ''}`}>
                    Producto Individuales y Masivos
                </button>
                <button
                    onClick={() => handleButtonClick('Canales Digitales')}
                    className={`bg-[#1976d2] text-white px-4 py-2 rounded hover:bg-[#073079] ${division === 'Canales Digitales' ? 'bg-[#042164]' : ''}`}>
                    Canales Digitales
                </button>
                <button
                    onClick={() => handleButtonClick('Colectivos')}
                    className={`bg-[#1976d2] text-white px-4 py-2 rounded hover:bg-[#073079] ${division === 'Colectivos' ? 'bg-[#042164]' : ''}`}>
                    Colectivos
                </button>
                <button
                    onClick={() => handleButtonClick('TPD')}
                    className={`bg-[#1976d2] text-white px-4 py-2 rounded hover:bg-[#073079] ${division === 'TPD' ? 'bg-[#042164]' : ''}`}>
                    TPD
                </button>
                <button
                    onClick={() => handleButtonClick('Finanzas y Corporación')}
                    className={`bg-[#1976d2] text-white px-4 py-2 rounded hover:bg-[#073079] ${division === 'Finanzas y Corporación' ? 'bg-[#042164]' : ''}`}>
                    Finanzas y Corporación
                </button>
            </div>

        

            {loading && <div className="text-center mt-4">Cargando...</div>}
            {error && <div className=" text-center mt-4">{error}</div>}

            {respuestas && (
                <div className="mt-4 overflow-x-auto">
                    <table className="min-w-full table-auto text-sm text-center text-[#333840] dark:text-gray-400 border border-[#1976d2]">
                        <thead className="text-xs text-white uppercase bg-[#1976d2] border border-[#1976d2]">
                            <tr>
                                <th scope="col" className="py-3 px-6">Madurez</th>
                                <th scope="col" className="py-3 px-6">Promedio global</th>
                                <th scope="col" className="py-3 px-6">Desviacion estandar</th>
                                <th scope="col" className="py-3 px-6">tNPS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="py-4 px-6">{getMaturityLevel(respuestas.madurez)}</td>
                                <td className="py-4 px-6">{respuestas.promedioGlobal}</td>
                                <td className="py-4 px-6">{respuestas.desviacionEstandar}</td>
                                <td className="py-4 px-6">{(respuestas.NPS)* 100 + "%"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            <div className="grid grid-cols-5 gap-1 justify-evenly">
                <div className="col-span-3">
                    {blob && <img src={URL.createObjectURL(blob)} alt="Imagen desde Blob" />}
                    
                </div>
                <div className="col-span-2">
                    {ImageURL && <img src={ImageURL} alt="tabla" />}
                </div>
            </div>
        </div>
    );
};



export default Division;
